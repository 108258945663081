import React, { useContext } from 'react';
import { NORTON_SEAL } from '../../config/constants/images';
import ThemeContext from '../../providers/ThemeProvider';

const HeaderNorton = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="norton-container">
        <img alt="Logo" src={`${NORTON_SEAL}`} />
      </div>
      <style jsx>{`
        .norton-container {
          display: flex;
          min-height: 41px;
          align-items: center;
          justify-content: center;
          max-width: 150px;
        }

        .norton-container img {
          width: 100%;
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .norton-container {
            display: flex;
            min-height: 75px;
          }
        }
      `}</style>
    </>
  );
};

export default HeaderNorton;
