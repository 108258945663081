import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const TileBody = ({
  children,
  nopadding,
  nobackground,
  className,
  conditional = true,
}) => {
  // enable tile to be 'turned off' as required
  if (conditional === false) {
    return <>{children}</>;
  }

  return (
    <>
      <div
        className={classNames(
          'tile-body',
          { 'tile-body--no-padding': nopadding },
          className,
        )}
      >
        {nobackground ? children : <div className="tile-main">{children}</div>}
      </div>
      <style jsx>{`
        .tile-body--no-padding {
          padding: 0;
        }
      `}</style>
    </>
  );
};

TileBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  conditional: PropTypes.bool,
  nobackground: PropTypes.bool,
  nopadding: PropTypes.bool,
};

export default TileBody;
