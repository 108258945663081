import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
/* import FooterLogin from '../components/footer/FooterLoginBlock'; */
import FooterPrivacy from '../components/footer/FooterPrivacy';
import HeaderEmpty from '../components/header/HeaderEmpty';
import { SITE_WOWCHER } from '../config/setup/setup';

const DashboardLayout = ({ children, extraClasses }) => {
  const Environment = process.env.NEXT_PUBLIC_SITE || SITE_WOWCHER;
  const router = useRouter();
  const isPrivacy = router.asPath === '/privacy-policy';

  return (
    <>
      <div className={extraClasses}>
        <HeaderEmpty logoDisabled site={Environment} />
        <div className="app app--full-height" id="main-view">
          {children}
        </div>
        {isPrivacy && <FooterPrivacy />}
      </div>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.array,
  extraClasses: PropTypes.string,
};

export default DashboardLayout;
