/* eslint-disable jsx-a11y/no-onchange */
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { LOGOUT } from '../../../config/text/text';
import { hashCode } from '../../../helpers/hash';
import { Logout } from '../../../helpers/logout';
import ThemeContext from '../../../providers/ThemeProvider';
import useToast from '../toast/UseToast';

const SelectMenu = ({
  selected,
  options,
  handleChange,
  extraClasses,
  dataqa,
}) => {
  const theme = useContext(ThemeContext);
  const toast = useToast();
  const dispatch = useDispatch();

  const selectedMenu =
    options[options.findIndex((item) => item.value.includes(selected))];

  const absoluteValue = selectedMenu ? selectedMenu.value : null;
  const [selectedItem, setSelectedItem] = useState(absoluteValue);

  if (!absoluteValue) return null;
  if (!options || !options.length) return null;

  const hashCodeAux =
    options && options.length
      ? hashCode(options.map((item) => item.name || '').join(''))
      : '';

  const handleChangeAction = (event) => {
    const value = event.currentTarget.value;

    if (value === LOGOUT) {
      Logout(event, toast, dispatch);

      return;
    }

    setSelectedItem(value);
    handleChange(
      options[options.findIndex((item) => item.value === value)],
      event,
    );
  };

  return (
    <>
      <div className={`menu-select__container ${extraClasses}`}>
        <select
          className="menu-select__dropdown"
          data-qa={dataqa}
          onChange={(e) => handleChangeAction(e)}
          value={selectedItem}
        >
          {options.map((item, index) => (
            <option
              className="menu-select__option"
              key={`select-menu--${hashCodeAux}-${index}`}
              label={item.name}
              value={item.value}
            >
              {item.name}
            </option>
          ))}
          <option className="menu-select__option" label={LOGOUT} value={LOGOUT}>
            {LOGOUT}
          </option>
        </select>
        <div className="menu-select__icon">
          <FontAwesomeIcon
            className="timer__icon"
            icon={faAngleDown}
            style={{ height: 20, width: 18 }}
          />
        </div>
      </div>
      <style jsx>{`
        .menu-select__container {
          position: relative;
          background: ${theme.colors.minimenu};
          border-radius: 10px;
        }
        .menu-select__dropdown {
          display: block;
          font-size: 15px;
          font-weight: 400;
          color: ${theme.colors.textonprimary};
          width: 100%;
          padding: 10px 0 10px 14px;
          border: 0;
          background: 0 0;
          appearance: none;
          z-index: 2;
          position: relative;
        }
        .menu-select__dropdown option {
          color: ${theme.colors.headingtext};
        }
        .menu-select__dropdown:focus {
          outline: 0;
          border: 0;
          box-shadow: 0 1px 12px rgba(51, 51, 51, 0.2);
        }
        .menu-select__icon {
          position: absolute;
          top: 0;
          right: 0;
          padding: 12px;
          z-index: 0;
          color: ${theme.colors.textonprimary};
        }

        // extraClasses
        .menu-select__container.visibleBorder {
          border: 1px solid ${theme.colors.textonprimary};
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
        }
        .menu-select__container.noBackground {
          background: none;
        }
        .menu-select__container.fontSize19 select {
          font-size: 19px;
        }

        .menu-select__container.fontSize24 select {
          font-size: 24px;
        }
      `}</style>
      <style global jsx>{`
        .menu-select__container.fontSize24 .menu-select__icon svg {
          height: 30px !important;
        }
        .menu-select__container.fontSize19 .menu-select__icon svg {
          height: 25px !important;
        }
      `}</style>
    </>
  );
};

SelectMenu.propTypes = {
  dataqa: PropTypes.string,
  extraClasses: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
};

SelectMenu.defaultProps = {
  dataqa: null,
  extraClasses: '',
  handleChange: () => {},
};

export default SelectMenu;
