import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';

const Tile = ({
  children,
  title,
  extraClasses,
  margin,
  conditional = true,
}) => {
  const theme = useContext(ThemeContext);
  const tileClass = extraClasses ? `tile tile--${extraClasses}` : `tile`;

  // enable tile to be 'turned off' as required
  if (conditional === false) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={classNames(tileClass, { 'tile--margin': margin })}>
        {title && <div className="tile-header">{title}</div>}
        {children}
      </div>
      <style jsx>{`
        .tile-header {
          text-transform: ${theme.text.transform};
          border-color: ${theme.colors.tilebordercolor};
        }
        @media (min-width: ${theme.breakpoints.mdUp}) {
          .tile--margin {
            margin-bottom: 3rem;
          }
        }
      `}</style>
    </>
  );
};

Tile.propTypes = {
  children: PropTypes.any,
  conditional: PropTypes.bool,
  extraClasses: PropTypes.string,
  margin: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Tile;
