import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { AN_ERROR_OCCURED } from '../config/text/text';
import { resetBasket } from '../redux/actions/basket';
import { logoutUser, resetUser } from '../redux/actions/user';
import { resetWishlist } from '../redux/actions/wishlist';
import { trackEvent } from './analytics';
import { setUserVipStatusToLocal } from './user';

// eslint-disable-next-line max-params
export const Logout = async (event, toast, dispatch, setShowModal = false) => {
  if (event) event.preventDefault();

  if (Cookies.load(COOKIES.basketToken))
    Cookies.remove(COOKIES.basketToken, {
      domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
      path: '/',
    });

  Cookies.remove(COOKIES.userLoggedIn, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    path: '/',
  });
  Cookies.remove(COOKIES.userEmail, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    path: '/',
  });
  Cookies.remove(COOKIES.wishlistId, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    path: '/',
  });

  try {
    await logoutUser();
    if (setShowModal) setShowModal(false);
    trackEvent('log_out');
    setUserVipStatusToLocal();
    dispatch(resetUser());
    dispatch(resetBasket());
    dispatch(resetWishlist());
  } catch (error) {
    toast.addToast(error.message || AN_ERROR_OCCURED, 'toast-error');
  }
};
