import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { FEATURE_FLAGS, REFER_A_FRIEND } from '../../config/setup/setup';
import { MINI_MENU, RAF_ENABLED_MINI_MENU } from '../../config/text/text';
import { redirectToURL } from '../../helpers/url';
import ThemeContext from '../../providers/ThemeProvider';
import SelectMenu from '../_generic/select-menu/SelectMenu';

const MyAccountMobileNavigation = () => {
  const theme = useContext(ThemeContext);
  const router = useRouter();

  // RAF Enabled mobile menu controlled by the env variable
  const rafEnabled = FEATURE_FLAGS[REFER_A_FRIEND];

  const options = rafEnabled ? RAF_ENABLED_MINI_MENU : MINI_MENU;

  return (
    <>
      <div className="select">
        <SelectMenu
          handleChange={(item) => redirectToURL(item.value, router)}
          options={options}
          selected={router.pathname}
        />
      </div>
      <style jsx>{`
        .select {
          display: block;
          width: 177px;
        }

        @media (min-width: ${theme.breakpoints.mdUp}) {
          .select {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

export default MyAccountMobileNavigation;
