/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MYACCOUNT_LOGO_MOBILE } from '../../config/constants/images';
import { LINK_MAIN_HOME } from '../../config/links/links';
import { isLivingSocialBrand } from '../../helpers/pages';
import ThemeContext from '../../providers/ThemeProvider';
import HeaderNorton from './HeaderNorton';
import MyAccountMobileNavigation from './MyAccountMobileNavigation';

export default function HeaderEmpty({
  site,
  logoDisabled,
  hasHeaderNorton,
  hasMobileNavigation = false,
}) {
  const router = useRouter();
  const theme = useContext(ThemeContext);
  const path = router.asPath;
  const isVoucherPage = path.includes(`/myaccount/`);
  const isAddonPage = path.includes(`/order-addon/`);
  const isVipUser = useSelector((state) => state.user.userprofile.vipUser);
  const isLivingSocial = isLivingSocialBrand();

  return (
    <>
      <header className="header-empty">
        <div className="view">
          <Link href={logoDisabled ? '' : LINK_MAIN_HOME}>
            <a className="logo">
              <img
                alt="logo"
                className="fivepm-logo"
                src={`${
                  isVoucherPage && isVipUser
                    ? theme.images.vipheaderlogo
                    : theme.images.headerlogo
                }`}
                width={isVipUser && isLivingSocial ? 149 : 90}
              />
            </a>
          </Link>
          <Link href={logoDisabled ? '' : LINK_MAIN_HOME}>
            <a className="mobile-logo">
              <img
                alt="logo"
                src={MYACCOUNT_LOGO_MOBILE[process.env.NEXT_PUBLIC_BRAND]}
                width={30}
              />
            </a>
          </Link>
          {/*  <HeaderNorton /> */}
          {hasMobileNavigation && <MyAccountMobileNavigation />}
        </div>
      </header>
      <style jsx>{`
        .header-empty {
          border-bottom: ${'1px solid' + theme.colors.navborder};
          background-color: #5a266a;
          overflow: hidden;
          min-height: 75px;
        }
        .mobile-logo {
          display: none;
        }
        .view {
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 1064px;
          margin: 0 auto;
          padding: 0 20px;
        }
        .fivepm-logo {
          margin-top: 20px;
        }
        a:-webkit-any-link {
          cursor: ${isAddonPage ? 'default' : 'pointer'};
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .header-empty {
            padding: 5px 10px;
            background: ${theme.colors.primary};
            border-bottom: none;
          }
          .logo {
            display: none;
          }
          .mobile-logo {
            display: block;
            line-height: 64px;
          }
          :global(.header-empty .norton-container) {
            display: ${hasHeaderNorton ? 'flex' : 'none'};
          }
        }
      `}</style>
    </>
  );
}

HeaderEmpty.propTypes = {
  hasHeaderNorton: PropTypes.bool,
  hasMobileNavigation: PropTypes.bool,
  logoDisabled: PropTypes.bool,
  site: PropTypes.string.isRequired,
};
